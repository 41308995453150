<template>
  <div>
    <Spinner v-show="cApiSending" />
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>
    <div class="container-fluid mt--6">
      <div
        v-show="cPromiseResolved"
        class="alert"
        :class="[cAlertStatus]"
      >
        {{ cAlertMessage }}
      </div>
      <div class="row">
        <div class="col-md-6">
          <ServiceForm @submit="handleSubmit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleBindingMixin from "@/mixins/views/Store/ModuleBindingMixin";
const { ServiceViewModule } = ModuleBindingMixin

import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import Spinner from "@/components/Atoms/Spinner.vue";
import ServiceForm from "@/components/Service/ServiceForm.vue";

export default {
  name: 'ProductTagAdd',
  components: {
    ServiceForm,
    BreadcrumbHeader,
    Spinner
  },
  mixins: [ ServiceViewModule ],
  computed: {
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Usługi', link: {name: 'service-list'}},
        {title: this.$route.params.id ? `Usługa ID: ${this.$route.params.id}` : 'Nowa usługa'}
      ];
    }
  },
  async beforeMount() {
    const id = this.$route.params.id
    if (!id) {
      return
    }
    await this.handleMounting(id)
  },
  watch: {
    $route(to, from) {
      this.handleMounting(to.params.id)
    }
  },
  methods: {
    async handleMounting(id){
      await this.$store.dispatch('ServiceViewModule/resetForm')
      await this.handleFetchItem({ token: this.$store.state.Auth.token, id })
    },
    async handleSubmit(payload) {
      if ( payload.id ) {
        await this.handlePatchItem(payload)
      } else {
        console.log(payload)
        await this.handlePostItem(payload)
      }
    }
  }
};
</script>
