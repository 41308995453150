<template>
  <card class="c-product-tag">
    <div slot="header">
      <h3>
        {{ item.id ? 'Edycja usługi' : 'Dodawanie nowej usługi'}}
      </h3>
    </div>
    <form
      @submit.prevent="handleFormSubmit"
      :class="cApiSending ? 'opacity-60' : ''"
    >
      <div class="row">
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              required
              name="name"
              error="Test"
              label="Podaj nazwę usługi"
              v-model="cName"
            />
          </async-item>
        </div>
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              label="Czy aktywna?"
            >
              <base-switch
                v-model="cActive"
                on-text="Tak"
                off-text="Nie"
              />
            </base-input>
          </async-item>
        </div>
      </div>
      <async-item :loading="loading" :height="45">
        <base-input
          name="long-description"
          label="Długi opis"
        >
          <rich-editor-component v-model="cLongDescription" />
        </base-input>
      </async-item>
      <div class="row">
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              name="short-description"
              error="Test"
              label="Krótki opis"
              v-model="cShortDescription"
            />
          </async-item>
        </div>
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              name="amount"
              error="Test"
              label="Kwota"
              v-model="cAmount"
            />
          </async-item>
        </div>
        <div class="col-md-6">
          <async-item :loading="loading" :height="45">
            <base-input
              name="ean"
              error="Test"
              label="EAN"
              v-model="cEan"
            />
          </async-item>
        </div>
      </div>
      <div class="pt-4 text-right">
        <async-item :loading="loading" :height="43" :width="86" inline>
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formStatus === 'sending'"
          >Zapisz</button>
        </async-item>
      </div>
    </form>
  </card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ModuleBindingMixin from "@/mixins/views/Store/ModuleBindingMixin";
const { ServiceViewModule } = ModuleBindingMixin

import AsyncItem from "@/components/AsyncItem.vue";
import RichEditorComponent from "@/components/RichEditor/RichEditorComponent.vue";

const valueFactory = (field) => ({
  get() {
    return this.item[field]
  },
  set(value) {
    this.setItem({
      [field]: value
    })
  }
})

export default {
  name: "ServiceForm",
  components: {
    RichEditorComponent,
    AsyncItem,
  },
  mixins: [ ServiceViewModule ],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('ServiceViewModule', [ 'item' ]),
    cName: valueFactory('name'),
    cActive: valueFactory('active'),
    cAmount: valueFactory('amount'),
    cEan: valueFactory('ean'),
    cLongDescription: {
      get() {
        return this.item.description.long
      },
      set(value) {
        this.setItem({
          description: {
            ...this.item.description,
            long: value
          }
        })
      }
    },
    cShortDescription: {
      get() {
        return this.item.description.short
      },
      set(value) {
        this.setItem({
          description: {
            ...this.item.description,
            short: value
          }
        })
      }
    },
  },
  methods: {
    ...mapMutations('ServiceViewModule', ['setItem']),
    ...mapActions('ServiceViewModule', ['handlePostItem']),
    async handleFormSubmit() {
      const payload = {
        token: this.$store.state.Auth.token,
        data: {
          name: this.cName,
          active: this.cActive,
          ean: this.cEan,
          amount: this.cAmount,
          description: {
            long: this.cLongDescription,
            short: this.cShortDescription
          }
        },
        ...( this.item.id ? { id: this.item.id } : {})
      }
      this.$emit('submit', payload )
    }
  }
}
</script>

<style scoped>
.opacity-60 {
  opacity: 0.6;
}
</style>
